var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path !== '/404'),expression:"$route.path !== '/404'"}],ref:"header"}),_c('router-view',{class:[_vm.$route.path != '/' && _vm.$route.path != '/404' ? 'contentsBody' : ''],on:{"headerOn":_vm.headerOn}}),_c('ContactBtn',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.path != '/404' &&
      _vm.$route.path != '/contact' &&
      _vm.$route.path != '/contact/thanks' &&
      _vm.$route.path != '/contact_simulation'
    ),expression:"\n      $route.path != '/404' &&\n      $route.path != '/contact' &&\n      $route.path != '/contact/thanks' &&\n      $route.path != '/contact_simulation'\n    "}]}),_c('Footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path !== '/404'),expression:"$route.path !== '/404'"}]}),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cookiePopup"},[_c('div',{staticClass:"cookiePopup__inner"},[_c('div',{staticClass:"cookiePopup__txt"},[_vm._v(" このwebサイトではサイトの利便性の向上を目的にクッキーを使用します。 "),_c('br',{staticClass:"pcOnly"}),_c('a',{attrs:{"href":"https://www.luci.co.jp/led/jp/privacy/","target":"_blank"}},[_vm._v("プライバシーポリシー")]),_vm._v("に基づいたデータの取得と利用に同意をお願いいたします。 ")]),_c('div',{staticClass:"cookiePopup__btns"},[_c('button',{staticClass:"cookiePopup__btns__ok",attrs:{"id":"cokkie_ok"}},[_vm._v("同意する")]),_c('button',{staticClass:"cookiePopup__btns__ng",attrs:{"id":"cokkie_ng"}},[_vm._v("Cookieを無効化")])])])])
}]

export { render, staticRenderFns }