import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueHead from 'vue-head'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

Vue.use(VueHead)
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
