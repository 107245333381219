<template>
  <div class="wrapper">
    <Header v-show="$route.path !== '/404'" ref="header" />
    <router-view
      :class="[$route.path != '/' && $route.path != '/404' ? 'contentsBody' : '']"
      @headerOn="headerOn"
    />
    <ContactBtn
      v-show="
        $route.path != '/404' &&
        $route.path != '/contact' &&
        $route.path != '/contact/thanks' &&
        $route.path != '/contact_simulation'
      "
    />
    <Footer v-show="$route.path !== '/404'" />
    <div class="cookiePopup">
      <div class="cookiePopup__inner">
        <div class="cookiePopup__txt">
          このwebサイトではサイトの利便性の向上を目的にクッキーを使用します。
          <br class="pcOnly" /><a
            href="https://www.luci.co.jp/led/jp/privacy/"
            target="_blank"
            >プライバシーポリシー</a
          >に基づいたデータの取得と利用に同意をお願いいたします。
        </div>
        <div class="cookiePopup__btns">
          <button class="cookiePopup__btns__ok" id="cokkie_ok">同意する</button>
          <button class="cookiePopup__btns__ng" id="cokkie_ng">Cookieを無効化</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContactBtn from "@/components/ContactBtn.vue";

export default {
  components: {
    Header,
    Footer,
    ContactBtn,
  },
  mounted: function () {
    this.checkCookie();
  },
  methods: {
    headerOn: function () {
      this.$refs.header.onHeader();
    },
    setCookie: function (value, expires) {
      document.cookie = `ag_co=` + value + `; expires=` + expires;
    },
    checkAgreeCookie: function (cookies) {
      for (var i = 0; i < cookies.length; i++) {
        var content = cookies[i].split("=");
        if (content[0].trim() == "ag_co") return content[1];
      }
      return "";
    },
    loadGTM: function () {
      var GTMScript = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5M63JH7');    
    `;
      var script = document.createElement("script");
      script.innerHTML = GTMScript;
      var f = document.getElementsByTagName("script")[0];
      f.parentNode.insertBefore(script, f);
    },
    deleteCookie: function (cookies) {
      for (var i = cookies.length - 1; i >= 0; i--) {
        var key = cookies[i].split("=")[0];
        if (
          key.trim().indexOf("_gat_") === 0 ||
          key.trim().indexOf("_gid") === 0 ||
          key.trim().indexOf("_ga") === 0 ||
          key.trim().indexOf("AMP_TOKEN") === 0 ||
          key.trim().indexOf("_gac_") === 0
        ) {
          var url = document.domain.split(".");
          var domain = url[url.length - 1];
          for (var j = url.length - 1; j >= 0; j--) {
            document.cookie =
              key.trim() + `=; domain = .` + domain + `; path=/; max-age=0`;
            document.cookie =
              key.trim() + `=; domain = ` + domain + `; path=/; max-age=0`;
            if (j != 0) domain = url[j - 1] + "." + domain;
          }
        }
      }
    },
    checkCookie: function () {
      // document.cookie = `ag_co=true; max-age=0`;

      var cookies = document.cookie.split(";");
      var agree = this.checkAgreeCookie(cookies);
      if (agree == "true") {
        this.loadGTM();
      } else if (agree == "false") {
        //
      } else {
        var body = document.querySelector("body"),
          btns = document.querySelector(".cookiePopup__btns");
        const _this = this;
        const delegate = function (e) {
          if (e.target.id == "cokkie_ok") {
            btns.removeEventListener("click", delegate);
            body.classList.remove("open-popup");
            var month = new Date();
            month.setMonth(month.getMonth() + 11);

            _this.setCookie("true", month.toUTCString());
            _this.loadGTM();
          } else if (e.target.id == "cokkie_ng") {
            btns.removeEventListener("click", delegate);
            body.classList.remove("open-popup");
            _this.deleteCookie(cookies);
            var day = new Date();
            day.setDate(day.getDate() + 1);

            _this.setCookie("false", day.toUTCString());
          }
        };
        body.classList.add("open-popup");
        btns.addEventListener("click", delegate);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contentsBody {
  padding-top: 85px;
}
.cookiePopup {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 20px;
  z-index: 1000;
  font-weight: 700;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  box-shadow: 0 -1px 0 #cec9c3;
  body.open-popup & {
    transform: translateY(0);
  }
  &__inner {
    max-width: 960px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__txt {
    font-size: 1.4rem;
    line-height: 1.8;
    a {
      color: rgb(96, 157, 255);
    }
  }
  &__btns {
    display: flex;
    margin-left: 70px;
    font-size: 1.6rem;
    button {
      width: 150px;
      height: 50px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.cookiePopup__btns__ok {
        color: #fff;
        background: $orColor;
      }
      &.cookiePopup__btns__ng {
        background: #fff;
        color: rgb(55, 107, 190);
      }
      + button {
        margin-left: 30px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .contentsBody {
    padding-top: 60px;
  }
  .cookiePopup {
    padding: 10px;
    &__inner {
      max-width: 100%;
      flex-direction: column;
    }
    &__txt {
      font-size: 1rem;
      line-height: 1.4;
    }
    &__btns {
      font-size: 1.2rem;
      margin: 10px auto 0;
      button {
        width: 100px;
        height: 30px;
        + button {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
