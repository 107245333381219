<template>
  <header
    class="header"
    :class="[{ home: $route.path === '/' }, { open: open }, { on: on }]"
  >
    <div class="header__inner">
      <div class="header__logoWrap">
        <router-link
          v-show="$route.path === '/'"
          class="header__logo mono"
          :to="{ name: 'Index'}"
        >
          <img src="/genbashiko/img/common/logo.png" alt="GENBA SHIKO" />
        </router-link>
        <router-link
          v-show="$route.path !== '/'"
          class="header__logo color"
          :class="{ on: open }"
          :to="{ name: 'Index'}"
        >
          <img src="/genbashiko/img/common/logo-color.png" alt="GENBA SHIKO" />
        </router-link>
      </div>
      <button class="header__navBtn spOnly" @click="navClick" ref="navBtn">
        <span />
        <p class="txt">
          MENU
        </p>
      </button>
      <div class="header__nav" :class="[nav, { on: navOn }]" @click="onClick">
        <div class="header__nav__inner">
          <div class="header__nav__logoBlock spOnly logo">
            <router-link :to="{ name: 'Index'}">
              <img src="/genbashiko/img/common/logo-color.png" alt="GENBA SHIKO" />
            </router-link>
          </div>
          <div
            class="header__navWrap"
            @mouseleave="mouseLeave"
            @click.stop="onClickLink"
          >
            <router-link
              class="header__nav__link about"
              :to="{ name: 'About'}"
              @mouseover.native="mouseOver"
            >
              <span>ABOUT</span>
            </router-link>
            <router-link
              class="header__nav__link service"
              :to="{ name: 'Service'}"
              @mouseover.native="mouseOver"
            >
              <span>SERVICE</span>
            </router-link>
            <router-link
              class="header__nav__link product"
              :to="{ name: 'Product'}"
              @mouseover.native="mouseOver"
            >
              <span>PRODUCT</span>
            </router-link>
            <router-link
              class="header__nav__link case"
              :to="{ name: 'Case'}"
              @mouseover.native="mouseOver"
            >
              <span>CASE</span>
            </router-link>
            <a
              class="header__nav__link column"
              href="/genbashiko/column"
              @mouseover="mouseOver"
            >
              <span>COLUMN</span>
            </a>
          </div>
          <p class="header__nav__linkWrap" @click.stop="onClickLink">
            <router-link
              :to="{ name: 'Download'}"
              class="header__nav__link download"
            >
              <span>DOWNLOAD</span>
            </router-link>
          </p>
          <p class="header__nav__linkWrap" @click.stop="onClickLink">
            <router-link :to="{ name: 'Contact'}" class="header__nav__link contactBtn">
              <span>CONTACT</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import gsap from "gsap";
export default {
  props: ["isTop"],
  data() {
    return {
      nav: "",
      navOn: false,
      open: false,
      on: true,
    };
  },
  created: function() {
    if (this.$route.path == "/") this.on = false;
  },
  mounted: function() {
    this.links = document.querySelectorAll(".header__nav__link");
    this.logoBlock = document.querySelectorAll(".header__nav__logoBlock");
  },
  methods: {
    onHeader: function() {
      this.on = true;
    },
    mouseOver: function(e) {
      this.navOn = true;
      if (e.currentTarget.classList.contains("about")) {
        this.nav = "about";
      }
      if (e.currentTarget.classList.contains("service")) {
        this.nav = "service";
      }
      if (e.currentTarget.classList.contains("product")) {
        this.nav = "product";
      }
      if (e.currentTarget.classList.contains("case")) {
        this.nav = "case";
      }
      if (e.currentTarget.classList.contains("column")) {
        this.nav = "column";
      }
    },
    mouseLeave: function() {
      this.navOn = false;
    },
    navClick: function() {
      if (!this.open) {
        this.reset();
        this.open = !this.open;
        this.$refs.navBtn.classList.add("open");
        let tl = new gsap.timeline();
        tl.to(this.logoBlock, 0.3, { opacity: 1, ease: "power1.inOut" }).to(
          this.links[0],
          0.3,
          { opacity: 1, y: 0, ease: "power1.inOut" },
          "-=.3"
        );
        for (let i = 1; i < this.links.length; i++) {
          tl.to(
            this.links[i],
            0.3,
            {
              opacity: 1,
              y: 0,
              ease: "power1.inOut",
            },
            "-=.23"
          );
        }
      } else {
        this.close();
      }
    },
    reset: function() {
      for (let i = 0; i < this.links.length; i++) {
        gsap.set(this.links[i], { opacity: 0 });
      }
    },
    close: function() {
      this.$refs.navBtn.classList.remove("open");
      let tl = new gsap.timeline();
      tl.to(this.logoBlock, 0.3, { opacity: 0 });
      for (let i = 0; i < this.links.length; i++) {
        tl.to(this.links[i], 0.3, { opacity: 0 }, "-=.3");
      }
      tl.add(() => {
        this.open = !this.open;
      });
    },
    onClickLink: function() {
      this.$refs.navBtn.classList.remove("open");
      this.open = false;
    },
    onClick: function() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 85px;
  width: 100%;
  margin: auto;
  top: 0;
  z-index: 100;
  background: #fff;
  color: #000;
  position: absolute;
  &.home {
    background: none;
    color: #fff;
    position: absolute;
    opacity: 0;
    transform: translateY(-50px);
    transition: all 0.5s ease-out;
    &.on {
      opacity: 1;
      transform: translateY(0);
    }
    .header__inner{
      max-width: 100%;
    }
  }
  &__inner {
    width: 100%;
    max-width: 1400px;
    min-width: 1100px;
    height: 85px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__navWrap {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__nav {
    height: 100%;
    position: relative;
    &__inner {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    &.on {
      &::after {
        background: $orColor;
      }
    }
    &::after {
      content: "";
      position: absolute;
      height: 4px;
      background: transparent;
      bottom: 0;
      transform: translateX(0);
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::after {
        transition: all 0.3s ease-out;
      }
    }
    &.about {
      &::after {
        width: 93px;
        transform: translateX(0);
      }
    }
    &.service {
      &::after {
        width: 100px;
        transform: translateX(92px);
      }
    }
    &.product {
      &::after {
        width: 112px;
        transform: translateX(192px);
      }
    }
    &.case {
      &::after {
        width: 80px;
        transform: translateX(304px);
      }
    }
    &.column {
      &::after {
        width: 108px;
        transform: translateX(381px);
      }
    }
    &__linkWrap {
      height: 100%;
    }
    &__link {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0 20px;
      height: 100%;
      justify-content: center;
      border-bottom: 4px solid transparent;
      font-family: $Lato;
      transition: all 0.3s ease-in;
      &.download {        
        width: 130px;
        margin: 0;
        background: $orColor;
        color: #fff;
        &::after {
          content: none;
        }
        &:hover {
          background: #fff;
          color: $orColor;
        }
      }
      &.contactBtn {
        width: 130px;
        margin: 0;
        background: $brColor;
        color: #fff;
        &::after {
          content: none;
        }
        &:hover {
          background: #fff;
          color: $brColor;
        }
      }
      &:hover {
        color: $orColor;
        transition: all 0.3s ease-out;
      }
      a {
        transition: none;
        &:hover {
          transition: none;
        }
      }
    }
  }
  &__logoWrap{
    flex: 0 1 184px;
    margin-left: 30px;
    min-width: 100px;
  }
  &__logo {
    img {
      width: 184px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .header {
    transition: all 0.3s ease-in;
    height: 60px;
    position: fixed;
    z-index: 100000;
    &.home {
      position: fixed;
    }
    &.open {
      .header__nav {
        display: block;
      }
    }
    &__inner {
      width: 100%;
      height: 60px;
      min-width:100%;
    }
    &__logoWrap{
      flex: auto;
      margin-left: 20px;
      min-width: 0;
    }
    &__logo {
      position: relative;
      img {
        width: 94px;
      }
    }
    &__navBtn {
      width: 60px;
      height: 60px;
      background: $brColor;
      color: #fff;
      z-index: 100000;
      position: relative;
      &::after,
      span,
      &::before {
        content: "";
        position: absolute;
        background: #fff;
        width: 30px;
        height: 3px;
        left: 15px;
        transition: all 0.3s ease-in;
      }
      &::before {
        top: 12px;
      }
      &::after {
        top: 20px;
      }
      span {
        top: 28px;
      }
      .txt {
        position: absolute;
        font-size: 1.1rem;
        font-family: $Lato;
        bottom: 10px;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease-in;
      }
      &.open {
        &::before,
        &::after,
        span,
        .txt {
          transition: all 0.3s ease-out;
        }
        &::before {
          top: 26px;
          transform: rotate(45deg);
        }
        &::after {
          top: 26px;
          transform: rotate(-45deg);
        }
        span {
          opacity: 0;
        }
        .txt {
          opacity: 0;
        }
      }
    }
    &__navWrap {
      display: block;
    }
    &__nav {
      position: absolute;
      width: 100%;
      height: auto;
      top: -60px;
      z-index: 10000;
      padding-top: 60px;
      display: none;
      &::before {
        content: "";
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
      }
      &::after {
        content: none;
      }
      &__inner {
        display: block;
      }
      &__logoBlock {
        display: flex;
        background: #fff;
        align-items: center;
        width: 100%;
        height: 60px;
        justify-content: center;
        position: relative;
        opacity: 0;
        a {
          width: 94px;
          margin: 0 auto 0 20px;
        }
      }
      &__link {
        font-size: 1.8rem;
        color: #000;
        background: #fff;
        width: 100%;
        height: 60px;
        font-weight: 400;
        position: relative;
        transition: none;
        margin: 0;
        // transform: translateY(100px);
        opacity: 0;
        span {
          letter-spacing: 0.12em;
          position: relative;
          &::before,
          &::after {
            content: "";
            background: #81807e;
            height: 1px;
            position: absolute;
            top: 58%;
            right: -34px;
            transform: translateY(-50%);
          }
          &::before {
            width: 18px;
          }
          &::after {
            width: 8px;
            transform: rotate(50deg);
            right: -35px;
            top: 42%;
          }
        }
        &.download,
        &.contactBtn {
          width: 100%;
          span {
            &::before,
            &::after {
              background: rgb(228, 228, 228);
            }
          }
        }
      }
    }
  }
}
</style>
